import ace from 'ace-builds'
// import 'ace-builds/webpack-resolver'  // keyboard shortcuts 必須使用 (e.g. Ctrl+F)
import 'ace-builds/src-noconflict/theme-clouds'
import 'ace-builds/src-noconflict/mode-markdown'
// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    props: {
        options: {
            type: Object,
            default: () => {},
        },
        value: {
            required: true,
            type: String,
        },
    },
    components: {
        Dialogue,
    },
    data: function() {
        return {
            id: (Math.floor(Math.random() * 100000) + 1) + Date.now(),
            OPTIONS: {
                readOnly: false,  // 是否唯讀
                minLines: 10,  // 最小行數
                maxLines: Infinity,  // 最大行數 (超過即顯示卷軸)
                fontSize: 16,  // 字體預設大小
                heading: {  // 標題工具設定
                    show: true,
                },
                bold: {  // 粗體工具設定
                    show: true,
                },
                italic: {  // 斜體工具設定
                    show: true,
                },
                underline: {  // 底線工具設定
                    show: true,
                },
                fontColor: {  // 字體顏色工具設定
                    show: true,
                },
                blockQuote: {  // 引言工具設定
                    show: true,
                },
                codeBlock: {  // 程式碼區塊工具設定
                    show: true,
                },
                horizontalRule: {  // 水平分隔線工具設定
                    show: true,
                },
                unorderedList: {  // 項目符號清單工具設定
                    show: true,
                },
                orderedList: {  // 編號清單工具設定
                    show: true,
                },
                link: {  // 連結工具設定
                    show: true,
                },
                image: {  // 圖片工具設定
                    show: false,
                    upload: {
                        url: '',  // 上傳 Api 網址
                        params: {},  // 上傳 Api 參數
                    },
                },
                audio: {  // 音檔工具設定
                    show: false,
                    upload: {
                        url: '',  // 上傳 Api 網址
                        params: {},  // 上傳 Api 參數
                    },
                    fileBaseUrl: `${process.env.VUE_APP_ROOT_API}/api/file/get_file_by_id`,
                },
                video: {  // 影片工具設定
                    show: false,
                    upload: {
                        url: '',  // 上傳 Api 網址
                        params: {},  // 上傳 Api 參數
                    },
                    fileBaseUrl: `${process.env.VUE_APP_ROOT_API}/api/file/get_file_by_id`,
                },
                teacherParagraph: {  // 僅老師觀看段落工具設定
                    show: false,
                },
            },

            aceEditor: null,
            uploadedImage: { files: [], error: '' },
            uploadedAudio: { files: [], error: '' },
            uploadedVideo: { files: [], error: '' },
            youtubeUrl: '',
            isPostingApi: {
                uploadImage: false,
                uploadAudio: false,
                uploadVideo: false,
            },
        }
    },
    computed: {
        headingSizes() {
            return [1, 2, 3, 4, 5];
        },
        fontColors() {
            return ['#3E3A38', '#E05252', '#F07525', '#23A4EC', '#43C65E'];
        },
        isValidYTUrl() {
            if (!this.youtubeUrl) {
                return true;
            }
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            return regExp.test(this.youtubeUrl);
        },
    },
    created: function() {
        // merge options
        for (const key in this.OPTIONS) {
            if (!this.options[key]) continue;
            if (typeof this.OPTIONS[key] === 'object') {
                this.OPTIONS[key] = { ...this.OPTIONS[key], ...this.options[key] };
            } else {
                this.OPTIONS[key] = this.options[key];
            }
        }
    },
    mounted: function() {
        // 建立 ace-editor 實例, 並初始化設定
        const editorElem = document.getElementById(`ace_${this.id}`);
        this.aceEditor = ace.edit(editorElem, {
            readOnly: this.OPTIONS.readOnly,
            minLines: this.OPTIONS.minLines,
            maxLines: this.OPTIONS.maxLines,
            fontSize: this.OPTIONS.fontSize,
            theme: 'ace/theme/clouds',
            mode: 'ace/mode/markdown',
            value: this.value,
            tabSize: 4,
            showPrintMargin: false,
        });
        // 自動 focus 內容最後一行
        this.aceEditor.focus();
        this.aceEditor.navigateFileEnd();
        // 調整行高為字體大小的 1.5倍
        this.aceEditor.container.style.lineHeight = 1.5;
        this.aceEditor.renderer.updateFontSize();
        // 字數過多超過寬度時會自動換行
        this.aceEditor.getSession().setUseWrapMode(true);
        // 綁定父元件 v-model 給定的值
        this.aceEditor.getSession().on('change', () => {
            this.$emit('input', this.aceEditor.getSession().getValue());
        });
    },
    methods: {
        // common
        addMarkBetweenText(mark1, mark2 = '') {
            if (!mark2) {
                mark2 = mark1;
            }

            let selectedText = this.aceEditor.getSelectedText(),  // 記錄目前選取字元
                selectionRange = this.aceEditor.selection.getRange();  // 記錄目前選取範圍
            let mark1Length = mark1.length,
                mark2Length = mark2.length;
            // 含標記的選取範圍
            let selectionRangeWithMark = this.$_.cloneDeep(selectionRange);
            selectionRangeWithMark.start.column -= mark1Length;
            selectionRangeWithMark.end.column += mark2Length;
            let isDeleteMark = false;  // 是否有移除標記

            // 有選取字元時, 則依據選取字元前後是否有目前要新增的標記做對應處理
            if (selectedText) {
                this.aceEditor.selection.setSelectionRange(selectionRangeWithMark, false);
                // 如果目前選取字元前後已有要新增的標記, 則移除標記
                if (this.aceEditor.getSelectedText() === `${mark1}${selectedText}${mark2}`) {
                    this.aceEditor.session.replace(selectionRangeWithMark, `${selectedText}`);
                    isDeleteMark = true;
                }
                // 如果目前選取字元前後沒有要新增的標記, 則對選取字元前後新增標記
                else {
                    this.aceEditor.session.replace(selectionRange, `${mark1}${selectedText}${mark2}`);
                }
            }
            // 沒有選取字元時, 則在當下位置新增標記
            else {
                this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `${mark1}${mark2}`);
            }

            // 標記前如果有選取字元, 則重新選取字元
            if (selectedText) {
                // 新的選取範圍
                let newSelectionRange = this.$_.cloneDeep(selectionRange);
                if (isDeleteMark) {
                    newSelectionRange.start.column -= mark1Length;
                    newSelectionRange.end.column -= mark1Length;
                } else {
                    newSelectionRange.start.column += mark1Length;
                    newSelectionRange.end.column += mark1Length;
                }
                this.aceEditor.selection.clearSelection();
                this.aceEditor.selection.setSelectionRange(newSelectionRange, false);
            }
            // 標記前如果沒有選取字元, 則對當下位置 focus
            else {
                // 目前游標位置在結束標記後一個位置, 移動游標位置至結束標記的前面一個位置
                this.aceEditor.moveCursorTo(this.aceEditor.getCursorPosition().row, this.aceEditor.getCursorPosition().column - mark2Length);
                this.aceEditor.selection.clearSelection();
                this.aceEditor.focus();
            }
        },
        addMarkAtLineStart(mark) {
            let cursorPos = this.aceEditor.getCursorPosition();  // 記錄目前游標所在位置
            let markLength = mark.length;

            // 移動游標位置至目前所在列的第一個位置, 並選取目前所在列第一個位置開始的標記
            this.aceEditor.navigateLineStart();
            let range = new ace.Range(cursorPos.row, 0, cursorPos.row, markLength+1);
            this.aceEditor.selection.setSelectionRange(range, false);

            // 如果前方標記為目前要新增的標記, 則移除標記
            if (this.aceEditor.getSelectedText() === `${mark} `) {
                this.aceEditor.session.replace(this.aceEditor.selection.getRange(), '');
            }
            // 如果前方標記不為目前要新增的標記, 則在目前所在列的第一個位置新增標記
            else {
                this.aceEditor.navigateLineStart();
                this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `${mark} `);
            }

            // 移動游標位置至目前所在列的最後一個位置
            let targetColumn = this.aceEditor.session.getLine(cursorPos.row).length;
            this.aceEditor.moveCursorTo(cursorPos.row, targetColumn);
            this.aceEditor.focus();
        },

        // styles
        addHeading(size) {
            this.addMarkAtLineStart('#'.repeat(size));
        },
        addBold() {
            this.addMarkBetweenText('**');
        },
        addItalic() {
            this.addMarkBetweenText('*');
        },
        addUnderline() {
            this.addMarkBetweenText('<u>', '</u>');
        },
        addFontColor(color) {
            this.addMarkBetweenText(`<font color="${color}">`, '</font>');
        },
        addBlockQuote() {
            this.addMarkAtLineStart('>');
        },
        addCodeBlock() {
            let originalCursorPos = this.aceEditor.getCursorPosition();  // 記錄原本游標所在位置

            // 有選取字元時, 則在選取字元前後新增 `` 標記
            if (this.aceEditor.getSelectedText()) {
                this.addMarkBetweenText('`');
            }
            // 沒有選取字元時, 則依據目前所在列是否有字元做對應處理
            else {
                this.aceEditor.navigateLineEnd();  // 移動游標位置至目前所在列的最後一個位置
                let currentCursorPos = this.aceEditor.getCursorPosition();  // 記錄目前游標所在位置

                // 目前游標位置為目前所在列的第一個位置, 表示目前所在列沒有任何字元, 則在目前所在列新增 ``` 區塊
                if (currentCursorPos.column == 0) {
                    this.aceEditor.session.insert(currentCursorPos, "```\n\n```");
                    this.aceEditor.navigateUp(1);
                }
                // 如果目前所在列含有字元, 則在當下位置新增 `` 標記
                else if (currentCursorPos.column > 0) {
                    this.aceEditor.moveCursorTo(originalCursorPos.row, originalCursorPos.column);
                    this.addMarkBetweenText('`');
                }

                this.aceEditor.focus();
            }
        },
        addHorizontalRule() {
            this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), "\n\n----------\n\n");
            this.aceEditor.focus();
        },
        addTeacherParagraph() {
            let mark = '###teacher-paragraph###';

            // 有選取字元時, 則依據選取字元前後一行是否含有標記決定新增或移除標記
            if (this.aceEditor.getSelectedText()) {
                let selectionRange = this.aceEditor.selection.getRange();
                let selectedText = this.aceEditor.getSelectedText();

                // 游標位置移動到選取範圍的開頭
                this.aceEditor.moveCursorTo(selectionRange.start.row, selectionRange.start.column);

                // 游標位置往上一行移動
                this.aceEditor.navigateUp(1);
                // 選取此行內容
                this.aceEditor.navigateLineStart();
                let startPos = this.aceEditor.getCursorPosition();
                this.aceEditor.navigateLineEnd();
                let endPos = this.aceEditor.getCursorPosition();
                let prevLineRange = { start: startPos, end: endPos };
                this.aceEditor.selection.setSelectionRange(prevLineRange, false);
                // 記錄原選取範圍的上一行內容
                let prevLineText = this.aceEditor.getSelectedText();

                // 游標位置移動到選取範圍最後一行的開頭
                this.aceEditor.moveCursorTo(selectionRange.end.row, selectionRange.end.column);

                // 游標位置往下一行移動
                this.aceEditor.navigateDown(1);
                // 選取此行內容
                this.aceEditor.navigateLineStart();
                startPos = this.aceEditor.getCursorPosition();
                this.aceEditor.navigateLineEnd();
                endPos = this.aceEditor.getCursorPosition();
                let nextLineRange = { start: startPos, end: endPos };
                this.aceEditor.selection.setSelectionRange(nextLineRange, false);
                // 記錄原選取範圍的下一行內容
                let nextLineText = this.aceEditor.getSelectedText();

                // 若此行內容與標記內容相同, 則取代原選取段落為移除標記的段落內容
                if (prevLineText === mark && nextLineText === mark) {
                    let newRange = { start: prevLineRange.start, end: nextLineRange.end };
                    this.aceEditor.session.replace(newRange, `${selectedText}`);
                }
                // 若此行內容非標記時, 則取代原選取段落為含有標記的段落內容
                else {
                    this.aceEditor.session.replace(selectionRange, `${mark}\n${selectedText}\n${mark}`);
                }
            }
            // 沒有選取字元時, 則依據目前所在列是否有字元做對應處理
            else {
                this.aceEditor.navigateLineEnd();  // 移動游標位置至目前所在列的最後一個位置
                let currentCursorPos = this.aceEditor.getCursorPosition();  // 記錄目前游標所在位置

                // 在目前所在列下一行新增含有標記的區塊
                let endOfLine = currentCursorPos.column > 0 ? '\n' : '';  // 如果目前所在列含有字元, 則加上換行符號
                this.aceEditor.session.insert(currentCursorPos, `${endOfLine}${mark}\n\n${mark}`);
                this.aceEditor.navigateUp(1);

                this.aceEditor.focus();
            }
        },
        addUnorderedList() {
            this.addMarkAtLineStart('*');
        },
        addOrderedList() {
            this.addMarkAtLineStart('1.');
        },
        addLink() {
            // 有選取字元時, 則對選取字元做連結標記, [選取文字](url)
            if (this.aceEditor.getSelectedText()) {
                let selectionRange = this.aceEditor.selection.getRange();
                this.aceEditor.session.replace(selectionRange, `[${this.aceEditor.getSelectedText()}](url)`);

                // 新的選取範圍
                let newSelectionRange = this.$_.cloneDeep(selectionRange);
                newSelectionRange.start.column += 1;
                newSelectionRange.end.column += 1;
                this.aceEditor.selection.setSelectionRange(newSelectionRange, false);
            }
            // 沒有選取字元時, 則在當下位置新增連結標記, [](url)
            else {
                this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `[](url)`);
                // 移動游標位置至 [] 的中間位置
                this.aceEditor.moveCursorTo(this.aceEditor.getCursorPosition().row, this.aceEditor.getCursorPosition().column - 6);
                this.aceEditor.selection.clearSelection();
                this.aceEditor.focus();
            }
        },
        addTable() {
            let defaultTable = `| 標題1 | 標題2 | 標題3 |\n| ----- | ----- | ----- |\n| 項目1 | 內容1 | 內容A |\n| 項目2 | 內容2 | 內容B |`;
            // 標題1 | 標題2 | 標題3
            // ----- | ----- | -----
            // 項目1 | 內容1 | 內容A
            // 項目2 | 內容2 | 內容B
            this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `\n\n${defaultTable}`);
            this.aceEditor.focus();
        },

        uploadImageFiles(event) {
            const files = event.target.files;
            const validFileTypes = ['jpg', 'jpeg', 'png'];
            const perFileLimitSize = 5242880;  // 5mb
            const maxFilesCount = 15;

            this.uploadedImage.error = this.$util.validateFiles(files, validFileTypes, perFileLimitSize, maxFilesCount);

            if (this.uploadedImage.error) {
                this.$store.dispatch('common/setAlert', { msg: this.uploadedImage.error, status: 'danger' });
                event.target.value = '';
                return;
            }

            this.uploadedImage.files = [...files];
            this.addImage();
            event.target.value = '';
        },
        addImage() {
            if (!this.OPTIONS.image.upload.url || !this.uploadedImage.files.length || this.isPostingApi.uploadImage) {
                return;
            }

            let params = new FormData();
            params.append('info', JSON.stringify(this.OPTIONS.image.upload.params));
            for (let i = 0; i < this.uploadedImage.files.length; i++) {
                params.append(`file${i}`, this.uploadedImage.files[i]);
            }

            this.isPostingApi.uploadImage = true;

            this.$httpRequest.post(this.OPTIONS.image.upload.url, params)
                .then(response => {
                    this.isPostingApi.uploadImage = false;

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            for (const el of result) {
                                const fileName = el.substring(el.lastIndexOf('/') + 1);
                                this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `\n![${fileName}](${el})\n`);
                                this.aceEditor.focus();
                            }
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.uploadImage = false;
                    console.error('Catched Error:', error);
                });
        },

        uploadAudioFiles(event) {
            const files = event.target.files;
            const validFileTypes = ['mp3'];
            const perFileLimitSize = 104857600;  // 100mb

            this.uploadedAudio.error = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (this.uploadedAudio.error) {
                this.$store.dispatch('common/setAlert', { msg: this.uploadedAudio.error, status: 'danger' });
                event.target.value = '';
                return;
            }

            this.uploadedAudio.files = [...files];
            this.addAudio();
            event.target.value = '';
        },
        addAudio() {
            if (!this.OPTIONS.audio.upload.url || !this.uploadedAudio.files.length || this.isPostingApi.uploadAudio) {
                return;
            }

            let params = new FormData();
            params.append('info', JSON.stringify(this.OPTIONS.audio.upload.params));
            for (let i = 0; i < this.uploadedAudio.files.length; i++) {
                params.append(`file${i}`, this.uploadedAudio.files[i]);
            }

            this.isPostingApi.uploadAudio = true;

            this.$httpRequest.post(this.OPTIONS.audio.upload.url, params)
                .then(response => {
                    this.isPostingApi.uploadAudio = false;

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            const fileUrl = `${this.OPTIONS.audio.fileBaseUrl}?id=${result}`;
                            this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `\n\n[_audio](${fileUrl})\n\n`);
                            this.aceEditor.focus();
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.uploadAudio = false;
                    console.error('Catched Error:', error);
                });
        },

        showAddVideoDialogue() {
            this.youtubeUrl = '';
            this.clearUploadedVideo();
            $(`#addVideoDialogue_${this.id}`).modal('show');
        },
        uploadVideoFile(event) {
            const files = event.target.files;
            const validFileTypes = ['mp4'];
            const perFileLimitSize = 2147483648;  // 2gb

            this.uploadedVideo.error = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (this.uploadedVideo.error) {
                event.target.value = '';
                return;
            }

            this.uploadedVideo.files = [...files];
            event.target.value = '';
        },
        clearUploadedVideo() {
            this.uploadedVideo = { files: [], error: '' };
        },
        addVideo() {
            // 加入 yt 影片
            if (this.youtubeUrl) {
                $(`#addVideoDialogue_${this.id}`).modal('hide');
                this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `\n\n[_yt](${this.youtubeUrl})\n\n`);
                this.aceEditor.focus();
            }
            // 上傳本地影片
            else {
                if (!this.OPTIONS.video.upload.url || !this.uploadedVideo.files.length || this.isPostingApi.uploadVideo) {
                    return;
                }

                let params = new FormData();
                params.append('info', JSON.stringify(this.OPTIONS.video.upload.params));
                for (let i = 0; i < this.uploadedVideo.files.length; i++) {
                    params.append(`file${i}`, this.uploadedVideo.files[i]);
                }

                this.isPostingApi.uploadVideo = true;
                this.$util.addBodyBackdrop();

                this.$httpRequest.post(this.OPTIONS.video.upload.url, params)
                    .then(response => {
                        this.isPostingApi.uploadVideo = false;
                        this.$util.removeBodyBackdrop();

                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                $(`#addVideoDialogue_${this.id}`).modal('hide');
                                const fileUrl = `${this.OPTIONS.video.fileBaseUrl}?id=${result}`;
                                this.aceEditor.session.insert(this.aceEditor.getCursorPosition(), `\n\n[_video](${fileUrl})\n\n`);
                                this.aceEditor.focus();
                            }
                        }
                    })
                    .catch(error => {
                        this.isPostingApi.uploadVideo = false;
                        this.$util.removeBodyBackdrop();
                        console.error('Catched Error:', error);
                    });
            }
        },
    },
}
